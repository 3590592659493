// the image size is 500px 238px
let data = {
    'projects':[ 
    {
        'key': 7,
        'projectName': 'Death In The City',
        'projectType': 'Web Application: Game',
        'projectUrl': 'https://www.tomsclassroom.com/deathinthecity',
        'imageUrl': 'https://tomsclassroom.com/deathinthecity.png',
        'flipSideText': `A murder mystery puzzle-solving game aimed at students of English as a second language. It's built using React Styled Components.`,
        'githubLink' : 'https://github.com/DoubleNemesis/QI-app',
    },
    {
        'key': 6,
        'projectName': 'Rhyme Drop',
        'projectType': 'Web Application: Game',
        'projectUrl': 'https://tomsclassroom.com/rhymedrop/',
        'imageUrl': 'https://tomsclassroom.com/rhymedrop.png',
        'flipSideText': `A word rhyming game of speed and skill ideal for English language students. It's built using React and styled components.`,
        'githubLink' : 'https://github.com/DoubleNemesis/rhyme-grab',
    },
    {
        'key': 5,
        'projectName': 'TomsClassroom.com',
        'projectType': 'Web Application',
        'projectUrl': 'https://tomsclassroom.com/collocations-tester/#/',
        'imageUrl': 'https://tomsclassroom.com/tomsclassroom3.png',
        'flipSideText': 'A full-stack application to help English language students prepare for exams. Built with Wordpress\'s RST API and React. Students can create an account to save their progress.',
        'githubLink' : 'https://github.com/DoubleNemesis/collocations-tester-nv',
    },
    {
        'key': 4,
        'projectName': 'Precision Recruitment',
        'projectType': 'Landing Page',
        'projectUrl': 'https://www.thomaspchant.com/projects/lp1',
        'imageUrl': 'https://www.thomaspchant.com/projects/lp1/lp1.jpg',
        'flipSideText': 'A beautiful and responsive landing page built with vanilla JavaScript and CSS. The page has simple but eye-catching animations. It could easily be refactored in React or NextJS',
        'githubLink' : 'https://github.com/DoubleNemesis/landing-page-one',
    },
    {
        'key': 3,
        'projectName': 'ExeterEnglishOnline.com',
        'projectType': 'Commercial Website',
        'projectUrl': 'http://www.ExeterEnglishOnline.com',
        'imageUrl': 'http://tomsclassroom.com/EEO.png',
        'flipSideText': 'A website for an online language school. The design is a collaborative effort. I worked mostly on the Wordpress backend and javascript.',
        'githubLink' : '',
    },
    {
        'key': 2,
        'projectName': 'BoilerOnEmergency.co.uk',
        'projectType': 'Commercial Website',
        'projectUrl': '/',
        'imageUrl': 'http://tomsclassroom.com/boileronemergency.png',
        'flipSideText': 'In the works at the moment: a heavily modified Wordpress site built to the clients exacting specifications. The site will be going live in early December 2021.',
        'githubLink' : '',
    },
    // {
    //     'key': 2,
    //     'projectName': 'React Countdown Timer',
    //     'projectType': 'Mini Project',
    //     'projectUrl': 'https://scrimba.com/scrim/co53944ccab8a50b3254076f0',
    //     'imageUrl': 'http://tomsclassroom.com/countdownNasa.png',
    //     'flipSideText': 'A countdown timer built using ReactJS styled components with a bit of animation. Code available on GitHub',
    //     'githubLink' : 'https://github.com/DoubleNemesis/CountDownReactJS',
    // },
    // {
    //     'key': 1,
    //     'projectName': 'React Xmas Wishlist',
    //     'projectType': 'Mini Project',
    //     'projectUrl': 'https://scrimba.com/scrim/co6f34b72a1d6344f3bfc1a51',
    //     'imageUrl': 'http://tomsclassroom.com/xmaswishlist.png',
    //     'flipSideText': 'A React wishlist app using FakeStore API. I also made a video of me building the first version of this app. It\'s 77 minutes long!',
    //     'githubLink' : 'https://github.com/DoubleNemesis/LoadsaMoney',
    // },
    // {
    //     'key': 0,
    //     'projectName': 'React Movie Search',
    //     'projectType': 'Mini Project',
    //     'projectUrl': 'https://1choj.csb.app/',
    //     'imageUrl': 'http://tomsclassroom.com/movieReact.png',
    //     'flipSideText': 'A movie search database built using ReactJs and an API to bring in movie data.',
    //     'githubLink' : 'https://github.com/DoubleNemesis/Movie-Search-API-React',
    // },
    ]}

export default data